import './App.css';
import { useState } from "react";
import hero_banner from "./static/business_women.jpg";
import brandDevelopmentImage from './static/service1.jpg';
import strategyConsultingImage from './static/service2.jpg';
import marketResearchImage from './static/service3.jpg';
import implementationSupportImage from './static/service4.jpg';
// import pricingImage from './static/pricing.jpg';
// import industriesImage from './static/industries.jpg';
// import blogImage from './static/blog.jpg';
import contactImage from './static/contact.jpg';
import termsImage from './static/terms.jpg';
import founderImage from './static/picofme.png';
import logoFooter from './static/logo.png';
import mobile_video from './static/mobile.mp4';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      <section className="hero_banner">
        <div className="logo">
          
          <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
            <ul>
              <li><a className="nav-links" href="/blog">Blog</a></li>
              <li><a className="nav-links" href="#packages">Packages</a></li>
              <li><a className="nav-links" href="#contact">Contact Us</a></li>
              <li><a className="nav-links" href="#about">About Us</a></li>
            </ul>
          </nav>
          
          <span>spark story consulting</span>
          <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>

        <img src={hero_banner} alt="Hero Banner" id="hero_banner_img" />
        <video id="bg_video" autoPlay muted loop playsInline>
          <source src={mobile_video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay"></div>
        <div className="landing_content">
          <div className="landing_flex">
            <div className="hero_banner_content">
              Every startup has its <br />own spark and story.
            </div>
            <a href="/#contact"><button className="consult">Consult Now</button></a>
          </div>
          <nav className='nav'>
            <ul>
              <li><a className="nav-links" href="/blog">Blog</a></li>
              <li><a className="nav-links" href="/#pricing">Packages</a></li>
              <li><a className="nav-links" href="/#contact">Contact Us</a></li>
              <li><a className="nav-links" href="/#about">About Us</a></li>
            </ul>
          </nav>
        </div>
      </section>

      <section className="about" id="about">
        <h1 className="section_head">About Us</h1>
        <div className="about_main">
          <div className="about_content">
            At Spark Consulting, we ignite the potential of tech startups, fashion sustainability brands, and healthcare innovators. We help them find their missing pieces to assemble into a perfect business puzzle. Specializing in brand development and storytelling, business strategy, market research, business basics, implementation plans, forming presentations, pitch decks, business proposals and plans, we provide personalized insights and support, with the help of a proper system we help you turn your visionary ideas into a successful reality. Our diverse expertise lies in storytelling, spreading market awareness for brands, and business writing in English and German. Spark Story Consulting is your trustful partner to help you grow and create a lasting impact on the audience. We help your story spark and shine.
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <h2>Services</h2>
        <div className="service-grid">
          <div className="service">
            <img src={brandDevelopmentImage} alt="Brand development and storytelling" className="service-image" />
            <div className="service-content">
              <h3>Brand Development and Storytelling</h3>
              <p>Share your story with us, and we will help you craft your identity in the market, position it, and help you connect with your audience with our storytelling techniques.</p>
            </div>
          </div>

          <div className="service">
            <img src={strategyConsultingImage} alt="Business strategy consulting" className="service-image" />
            <div className="service-content">
              <h3>Business Strategy Consulting</h3>
              <p>Do you often feel confused while planning out your growth strategies? We are here to help you and guide you with appropriate strategic planning for your business.</p>
            </div>
          </div>

          <div className="service">
            <img src={marketResearchImage} alt="Market research and analysis" className="service-image" />
            <div className="service-content">
              <h3>Market Research and Analysis</h3>
              <p>We share insights into market trends, competition, and customer behavior. Based on our analysis, we help lay out your plan based on our recommendations.</p>
            </div>
          </div>

          <div className="service">
            <img src={implementationSupportImage} alt="Implementation support" className="service-image" />
            <div className="service-content">
              <h3>Implementation Support</h3>
              <p>Assisting with the execution of strategies and plans is our key feature. We are here not just to guide you, but also to help you implement our discussed plans and strategies.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing" id="pricing">
        <h2>Pricing</h2>
        <div className="pricing-content">
          <p>This unique business basic package is for you if you have just started a business and need guidance for implementation of your idea to turn into a running business success. In this, we do explain each step of activity in a simplified form and help out with writing business plans to finalizing the market entry strategies.</p>
        </div>
      </section>

      <section className="industries">
        <h2>Industries</h2>
        <p>We intend to focus on industries like technology, healthcare, and fashion sustainability businesses. Our belief is that these industries can help this world a better place to live in. Overcoming challenges and making a place in the industry is often difficult for these industries because of immense competition. Collaborating with a common mission to connect to the people with the product is also our goal. We help tech startups position themselves in the market and connect the healthcare industry with the audience. For fashion sustainability, we help them with spreading market awareness to motivate people to shop thoughtfully and in an eco-friendly way.</p>
      </section>

      {/* <section className="blog">
        <img src={blogImage} alt="Blog" />
        <h2>Blog</h2>
        <p>Welcome to our world full of interesting business case studies, insights on industry trends, inspiring stories, and learning more about us.</p>
      </section> */}


      <section className="founder">
        <div className="founder_content">
          <h2>Meet the Founder</h2>
          <p>Your business story will be grown at Spark Consulting under a seasoned expert with a passion for driving growth and innovation. Meet Ms. Mridul Chandhok, founder of Spark Story Consulting. With a background in German language training and expertise in business growth consulting, Ms. Mridul brings a unique blend of linguistic expertise and strategic insight to the table. Her vision for Spark Story Consulting is to empower businesses through helping them to transform their visionary ideas into a successful reality. Empathy and being able to connect to the audience is her motto and strength.</p>
        </div>
        <div className="founder_img">

          <img src={founderImage} alt="Meet the Founder" />
        </div>
      </section>
      <section className="terms">
        <img src={termsImage} alt="Terms of Policy and Services" />
        <div className="terms_content">
          <h2>Terms of Policy and Services</h2>
          <p>At Spark Story Consulting, we are committed to protecting your privacy and ensuring a positive experience with our services. Our privacy policy outlines how we collect, use, and safeguard your personal information when you interact with our website or engage our consulting services. We collect data such as names, e-mail addresses, and other relevant information to enhance our services, communicate effectively, and improve user experience. Rest assured, we implement stringent security measures to protect your data from unauthorized access. By using our website and services, you agree to our terms of service, which detail your responsibilities, our intellectual property rights, and the limitations of our liability. We aim to provide accurate and reliable consulting services tailored to your needs, but we disclaim warranties and limit our liability to the fullest extent permitted by law. For any questions or concerns, please contact us directly. Our policies and terms may be updated periodically, and we encourage you to review them regularly.</p>
        </div>
      </section>
      <section className="contact" id="contact">
        <img src={contactImage} alt="Contact Us" />
        <div className="contact_content">
          <h2>Contact Us</h2>
          <p>We would love to know more about your business and see it grow with us. Contact us on the given details and let’s join a common mission!</p>
          <form className="contact-form">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="contact">Contact Number:</label>
            <input type="text" id="contact" name="contact" required />
            <button type="submit" className="submit-btn">Submit</button>
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logoFooter} alt="Company Logo" />
          </div>
          <div className="footer-links">
            <ul>
              <li><a href="#about">About Us</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#blog">Blog</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          {/* <div className="footer-contact">
            <p>123 Business Road, Suite 456</p>
            <p>City, State, 78910</p>
            <p>Email: info@company.com</p>
            <p>Phone: (123) 456-7890</p>
          </div> */}
          <div className="footer-social">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Spark Story Consulting. All rights reserved.</p>
          <p>Developed by Depixel Tech Ventures</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
